
// Fonts
@import url(https://fonts.googleapis.com/css?family=Open+Sans:300,400,600|Roboto+Slab:700);

// Variables
@import "variables";

// Bootstrap
@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap";

// Forms
@import "forms";

// Close
@import "close";

// Tables
@import "tables";

// Navbar
@import "navbar";

// Buttons
@import "buttons";

// Typography
@import "type";

// Wells
@import "wells";

html {
  min-height: 100%;
  position: relative;
}
body { margin-bottom: 60px;}
.container-large {
  width: 1200px;
}
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, .5);
  display: table;
  transition: opacity .3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.modal-container {
  width: 300px;
  margin: 0px auto;
  padding: 20px 30px;
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, .33);
  transition: all .3s ease;
  font-family: Helvetica, Arial, sans-serif;
}

.modal-header h3 {
  margin-top: 0;
}

.modal-body {
  margin: 20px 0;
}

.modal-default-button {
  float: right;
}

/*
 * the following styles are auto-applied to elements with
 * v-transition="modal" when their visiblity is toggled
 * by Vue.js.
 *
 * You can easily play with the modal transition by editing
 * these styles.
 */

.modal-enter, .modal-leave {
  opacity: 0;
}

.modal-enter .modal-container,
.modal-leave .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

.top20 { margin-top: 20px; }
.expand-transition {
  transition: all .5s ease;
}
.expand-enter, .expand-leave {
  height: 0;
  opacity: 0;
}
.loading {
  font-size: 18px;
}
.loading:after {
  overflow: hidden;
  display: inline-block;
  vertical-align: bottom;
  -webkit-animation: ellipsis steps(4,end) 900ms infinite;
  animation: ellipsis steps(4,end) 900ms infinite;
  content: "\2026"; /* ascii code for the ellipsis character */
  width: 0px;
}

@keyframes ellipsis {
  to {
    width: 1.25em;
  }
}

@-webkit-keyframes ellipsis {
  to {
    width: 1.25em;
  }
}

#storage-guide #guide-tabs {
  margin-bottom: 20px;
}
#storage-guide .details {
  background: none repeat scroll 0 0 #F0F0F0;
  border: 1px solid #CCCCCC;
  padding: 16px;
}
#storage-guide .details .title {
  font-size: 32px;
  font-weight: bold;
}
#storage-guide .nav-tabs {
  border: none;
}
#storage-guide .nav-tabs li {
  width: 13.7%;
}
#storage-guide .nav-tabs li + li {
  margin-left: 4px;
}
#storage-guide .nav-tabs li a {
  background: none repeat scroll 0 0 #DDDDDD;
  border: 1px solid #CDCDCD;
  border-radius: 4px;
  color: #AAAAAA;
  font-size: 30px;
  font-weight: bold;
  height: 116px;
  line-height: 90px;
  padding: 0;
  text-align: center;
  text-shadow: 0 1px 0 rgba(255, 255, 255, 0.65);
}
#storage-guide .nav-tabs li a:hover, #storage-guide .nav-tabs li.active a, #storage-guide .nav-tabs li.active a:hover {
  background: none repeat scroll 0 0 #C9C9C9;
  border-color: #8E8E8E;
  color: #424242;
}
#storage-guide .nav-tabs span.subtitle {
  display: block;
  font-size: 10px;
  line-height: 16px;
  position: absolute;
  text-transform: uppercase;
  top: 66px;
  width: 100%;
}
#storage-guide .nav-tabs span.subtitle:before {
  content: "[ ";
}
#storage-guide .nav-tabs span.subtitle:after {
  content: " ]";
}
.pad40 {
  padding: 40px;
}
.margin-top {
  margin-top: 30px;
}
.margin-small-top {
  margin-top: 15px;
}
.margin-none-top {
  margin-top: 0;
}
.margin-none-bottom {
  margin-bottom: 0;
}
.row {
  position: relative;
}
.bottom-align-text {
  position: absolute;
  bottom: 0;
  right: 0;
}
#toolbar {
  padding: 30px 0;
}
#toolbar button {
  margin-right: 10px;
  padding-right: 15px;
}
.alert-info {
  color: #fff;
  padding: 10px 40px;
  //display: inline;
  //padding-right: 20px;
  p, h3, h4 {
    color: #fff;
  }
}

.alert-info .btn {
  color: $brand-secondary;
}

.panel-interview {
  border: none;
  border-radius: 0;
}
.panel-interview:hover {
  cursor: pointer;
  img {
    -webkit-filter: brightness(200%);
    filter: brightness(200%);
  }
}
.panel-interview .panel-footer {
  padding: 0;
  border: none;
  background-color: #fff;
}
.panel-interview .btn {
  border-radius: 0;
}
.panel-condensed .panel-body {
  padding: 10px;
}
.panel-info .panel-title {
  color: #fff;
}
.table > thead > tr > td.success, .table > thead > tr > th.success, .table > thead > tr.success > td, .table > thead > tr.success > th, .table > tbody > tr > td.success, .table > tbody > tr > th.success, .table > tbody > tr.success > td, .table > tbody > tr.success > th, .table > tfoot > tr > td.success, .table > tfoot > tr > th.success, .table > tfoot > tr.success > td, .table > tfoot > tr.success > th {
  background-color: #d0e9c6;
}
.table-hover > tbody > tr > td.success:hover, .table-hover > tbody > tr > th.success:hover, .table-hover > tbody > tr.success:hover > td, .table-hover > tbody > tr:hover > .success, .table-hover > tbody > tr.success:hover > th {
  background-color: #d1deda;
}
.table {
  font-size: 14px;
}

/* Units page */
#locations {
  h4.panel-title {
    color: #fff;
  }
  .well {
    padding: 10px;
  }
  .nav-pills > li > a {
    border-radius: 0;
    font-size: 14px;
    line-height: 1.5;
    margin: 0 5px;
    padding: 5px 10px;
    background-color: $brand-secondary;
    border-color: #39b6d2;
    color: #fff;
  }
  .nav-pills > li.active > a, .nav-pills > li > a:hover, .nav-pills > li > a:focus {
    background-color: #8ccdd9;
  }
  h5 {
    margin: 0 0 10px;
  }
  .panel-body h3 {
    margin: 10px 0 0;
    font-size: 20px;
  }
  .unit-tabs img.pull-right {
    position: relative;
    bottom: 3px;
    margin-left: -35px;
  }
}
.panel-clr.on {
  //background-color: #f2a969;
}
#call-notes{
  font-size: 14px;
  left: -3px;
  position: fixed;
  top: 143px;
  width: 290px;
  z-index: 1;
  .notes-container {
    background-color: #fff;
    border: 3px solid #4ebed7;
    border-radius: 3px;
    padding: 8px;
  }
  .notes-toggle {
    background-color: #4ebed7;
    color: #fff;
    position: absolute;
    top: 0;
    right: -25px;
    width: 27px;
    font-size: 20px;
    padding: 3px;
  }
}
/* Storage Features Modal */
#storageFeatures {
  .modal-body {
    padding: 15px 50px 20px;
  }
  .storageFeature h3 {
    margin-bottom: 0;
  }
}
/* Date picker */
.datepicker table tr td.active.active, .datepicker table tr td.active.disabled, .datepicker table tr td.active.disabled.active, .datepicker table tr td.active.disabled.disabled, .datepicker table tr td.active.disabled:active, .datepicker table tr td.active.disabled:hover, .datepicker table tr td.active.disabled.active:hover, .datepicker table tr td.active.disabled.disabled:hover, .datepicker table tr td.active.disabled:hover:active, .datepicker table tr td.active.disabled:hover:hover, .datepicker table tr td.active.disabled[disabled]:hover, .datepicker table tr td.active.disabled[disabled], .datepicker table tr td.active:active, .datepicker table tr td.active:hover, .datepicker table tr td.active.active:hover, .datepicker table tr td.active.disabled:hover, .datepicker table tr td.active:hover:active, .datepicker table tr td.active:hover:hover, .datepicker table tr td.active[disabled]:hover, .datepicker table tr td.active[disabled] {
  background-color: $brand-secondary;
  background-image: none;
}

.dropdown-submenu {
  position: relative;
}

.dropdown-submenu>.dropdown-menu {
  top: 0;
  left: 100%;
  margin-top: -6px;
  margin-left: -1px;
  -webkit-border-radius: 0 6px 6px 6px;
  -moz-border-radius: 0 6px 6px;
  border-radius: 0 6px 6px 6px;
}

.dropdown-submenu:hover>.dropdown-menu {
  display: block;
}

.dropdown-submenu>a:after {
  display: block;
  content: " ";
  float: right;
  width: 0;
  height: 0;
  border-color: transparent;
  border-style: solid;
  border-width: 5px 0 5px 5px;
  border-left-color: #ccc;
  margin-top: 5px;
  margin-right: -10px;
}

.dropdown-submenu:hover>a:after {
  border-left-color: #fff;
}

.dropdown-submenu.pull-left {
  float: none;
}

.dropdown-submenu.pull-left>.dropdown-menu {
  left: -100%;
  margin-left: 10px;
  -webkit-border-radius: 6px 0 6px 6px;
  -moz-border-radius: 6px 0 6px 6px;
  border-radius: 6px 0 6px 6px;
}

.ledger-item {
  h4 {
    border-top: 2px solid #a0d2d9;
    border-bottom: 2px solid #a0d2d9;
    line-height: 30px;
  }
  .pull-right {
    padding-top: 10px;
  }
}
.glyphicon.fast-right-spinner {
  -webkit-animation: glyphicon-spin-r 1s infinite linear;
  animation: glyphicon-spin-r 1s infinite linear;
}
@-webkit-keyframes glyphicon-spin-r {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}

@keyframes glyphicon-spin-r {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}
.pl-zero { padding-left: 0px; }
.pr-zero { padding-right: 0px; }
.plr-zero { padding-left: 0px; padding-right: 0; }
hr { border-style: dotted none none; border-width: 4px 0 0; }
.footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 60px;
  padding: 10px;
  background-color: #f5f5f5;
}
.max-height {
  max-height: 230px;
  overflow: auto;
}

/* Unit Grid unit header */
#unit-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  text-align: center;
  margin-bottom: 10px;

  .unit-counts {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    gap: 5%;

    .unit-count {
      h5 {
        margin-bottom: 4px;
        font-size: 80%;
      }
      small {
        font-size: 80%;
        display: inline-block;
        min-width: 10px;
        padding: 3px 7px;
        font-weight: bold;
        line-height: 1;
        color: #fff;
        text-align: center;
        white-space: nowrap;
        vertical-align: middle;
        border-radius: 10px;
      }
    }
  }
}

.col-md-4 {
  #unit-header {
    flex-direction: column;

    .unit-counts {
      justify-content: space-evenly;
    }
  }
}