// Alerts
$state-info-bg: #4ebed7;
$state-info-border: #4ebed7;
$state-success-bg:               #dff0d8;
$state-success-border: darken(adjust-hue($state-success-bg, -10), 10%);

// Body
$body-bg: #e8e4db;
$text-color: #444;

// Borders
$laravel-border-color: darken($body-bg, 10%);
$list-group-border: $laravel-border-color;
$navbar-default-border: $laravel-border-color;
$panel-default-border: $laravel-border-color;
$panel-inner-border: $laravel-border-color;
$border-radius-small: 0;

// Brands
$brand-primary: #ee8312;
$brand-secondary: #4ebed7;
$brand-info: #8eb4cb;
$brand-success: #419641;
$brand-warning: #cbb956;
$brand-danger:  #bf5329;

// Buttons
$btn-default-color: $text-color;
$btn-secondary-color: #fff;
$btn-secondary-bg: $brand-secondary;
$btn-secondary-border: darken($btn-secondary-bg, 5%);

// Close
$close-font-weight: normal;
//$close-color: $brand-primary;

// Colors
$brown-dark: #c0b6a2;
$brown: #d3ccbd;
$brown-light: #e8e4db;

// Container sizes
$grid-gutter-width:         30px;
$container-large-desktop: (940px + $grid-gutter-width);

// Dropdowns
//** Hover color for dropdown links.
$dropdown-link-hover-color:      #fff;
//** Hover background for dropdown links.
$dropdown-link-hover-bg:         $brand-primary;
//** Active dropdown menu item text color.
$dropdown-link-active-color:     #fff;
//** Active dropdown menu item background color.
$dropdown-link-active-bg:        $brand-primary;

// Forms
$input-group-addon-bg: $brown-light;
$input-group-addon-clr: $brown-dark;
$input-border: $brown;
//$input-height-large: (ceil($font-size-large * $line-height-large) + ($padding-large-vertical * 2) + 6)
//$input-border: lighten($text-color, 40%);
//$input-border-focus: lighten($brand-primary, 25%);
//$input-color-placeholder: lighten($text-color, 30%);

// Links
$link-color: $brand-secondary;
$link-hover-color: darken($link-color, 15%);
$link-decoration: underline;
$link-hover-decoration: none;

// Navbar
$navbar-default-bg: #fff;

// Panels
$panel-default-heading-bg: #fff;
$panel-body-padding: 30px;
$panel-default-border: #fff;
$panel-primary-border: $brown;

// Popover
$popover-max-width:  350px;

// Tables
$table-bg-accent: #eeebe4;
$table-bg-hover: #eeebe4;
$table-border-color: #a0d2d9;

// Tooltips
//$tooltip-bg: $brown-light;
//$tooltip-color: #444;
$tooltip-max-width: 300px;
$tooltip-opacity: 0.9;

// Typography
$font-family-sans-serif: "Open Sans", sans-serif;
$font-family-serif: "Roboto Slab", serif;
$font-size-base: 16px;
$line-height-base: 1.6;
$font-size-large: 18px;
$font-size-h3: 25px;
$headings-font-weight: 400;
$line-height-h3: 1.3;
$state-success-text: $brand-success;
$hr-border: $brown;

// Wells
$well-bg: #fff;