.table {
  // Cells
  > thead,
  > tbody,
  > tfoot {
    > tr {
      > th,
      > td {
        border-top: 2px solid $table-border-color;
      }
    }
  }
  // Bottom align for column headings
  > thead > tr > th {
    border-top: 2px solid $table-border-color;
    border-bottom: 2px solid $table-border-color;
  }

  > thead,
  > tbody,
  > tfoot {
    > tr.no-border {
      > th,
      > td {
        border-top:none;
      }
    }
  }
}