.btn-secondary {
  color: $btn-secondary-color;
  background-color: $btn-secondary-bg;
  border-color: $btn-secondary-border;

  &:focus,
  &.focus {
    color: $btn-secondary-color;
    background-color: darken($btn-secondary-bg, 10%);
    border-color: darken($btn-secondary-border, 25%);
  }
  &:hover {
    color: $btn-secondary-color;
    background-color: darken($btn-secondary-bg, 10%);
    border-color: darken($btn-secondary-border, 12%);
  }
  &:active,
  &.active,
  .open > &.dropdown-toggle {
    color: $btn-secondary-color;
    background-color: darken($btn-secondary-bg, 10%);
    border-color: darken($btn-secondary-border, 12%);

    &:hover,
    &:focus,
    &.focus {
      color: $btn-secondary-color;
      background-color: darken($btn-secondary-bg, 17%);
      border-color: darken($btn-secondary-border, 25%);
    }
  }
  &:active,
  &.active,
  .open > &.dropdown-toggle {
    background-image: none;
  }
  &.disabled,
  &[disabled],
  fieldset[disabled] & {
    &:hover,
    &:focus,
    &.focus {
      background-color: $btn-secondary-bg;
      border-color: $btn-secondary-border;
    }
  }
}

.reservationForm .btn-sm {
  padding: 3px 10px;
  margin-bottom: -3px;
}