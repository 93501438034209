.navbar {
  margin-bottom: 0;
}
.navbar-default {
  border: none;
}
.navbar-brand {
    > span {
      font-size: 18px;
      font-family: $font-family-serif;
      font-weight: 700;
      vertical-align: bottom;
      color: #444;
      padding-left: 10px;
      position: relative;
      top: 4px;
    }
    > img {
      display: inline;
    }
}
.navbar-default .navbar-nav > li > a {
  font-weight: 700;
  color: #444;
}

